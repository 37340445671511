import React, {useCallback, useEffect, useMemo, useState} from 'react';
import styled from 'styled-components';
import * as Ant from 'antd';
import Block from '../../Widgets/Block';
import {renderProductConfig} from '../../Components/CartItem';
import {
  REVIEW_STATUS,
  REVIEW_STATUS_COLORS,
  PRODUCTION_STATUS,
  PRODUCTION_STATUS_COLORS,
} from '../../dictionary';
import Tag from '../../Widgets/Tag';
import Row from '../../Widgets/Row';
import Button from '../../Widgets/Button';
import {Edit} from '@styled-icons/boxicons-solid/Edit';
import UploadFileButton from '../../Components/UploadFileButton';
import {isOrderDisabled} from '../../Utils/OrderUtil';
import {useOutlet} from 'reconnect.js';
import Hint from '../../Widgets/Hint';
import OrderItemUtil from '../../Utils/OrderItemUtil';
const appConfig = require('../../data.json');

function sliceFilename(file) {
  file = decodeURIComponent(file);
  return (file && file.slice(file.lastIndexOf('/') + 1)) || '';
}

export default function OrderItem({
  orderItem,
  cartItem,
  order,
  productSpec,
  onUpdate,
  suppliers,
  reviewers,
  isAmountVisible,
}) {
  const [actions] = useOutlet('actions');
  const [editReview, setEditReview] = useState(false);
  const [editProduction, setEditProduction] = useState(false);
  const [values, _setValues] = useState();
  const [reviewer, setReviewer] = useState(
    orderItem.assignee ? orderItem.assignee.id : null,
  );
  const [confirming, setConfirming] = useState(false);
  const setValues = useCallback(
    (obj) => _setValues((prev) => ({...prev, ...obj})),
    [],
  );
  const attachment = orderItem.latest_attachment;
  const disabled =
    !values ||
    isOrderDisabled(order) ||
    orderItem.production_status === 'in_storage';

  const hint = useMemo(() => {
    return isOrderDisabled(order)
      ? '注意此訂單已進入不可編輯的狀態'
      : order.payment_status !== 'success'
      ? '注意此訂單尚未付款，無法進行審稿'
      : orderItem.file_upload && !orderItem.attachment_uploaded
      ? '必須上傳稿件，遵循訂單流程，避免訂單狀態錯誤'
      : orderItem.status !== 'accepted'
      ? '注意審稿合格後，無法切換審稿狀態'
      : orderItem.production_status === 'in_storage'
      ? '注意印製狀態已入庫，請勿再編輯任何狀態'
      : '';
  }, [order, orderItem]);

  const assignReviewer = useCallback(async () => {
    if (reviewer) {
      actions.setLoading(true);
      try {
        await actions.assignReviewer({
          order: orderItem.order,
          item_index: orderItem.item_index,
          assignee: parseInt(reviewer),
        });
        Ant.message.success('成功指派');
      } catch (ex) {
        Ant.message.error('指派失敗');
      }
      actions.setLoading(false);
    }
  }, [reviewer, orderItem]);

  const submit = async () => {
    actions.setLoading(true);
    try {
      await actions.editOrderItem({id: orderItem.id, ...values});
      Ant.message.success('儲存成功');
      onUpdate();
    } catch (err) {
      Ant.message.error('儲存錯誤');
    }
    actions.setLoading(false);
  };

  const addAttatchment = async (url) => {
    actions.setLoading(true);
    try {
      await actions.addAttatchment({
        order: orderItem.order,
        item_index: orderItem.item_index,
        url,
      });
      Ant.message.success('上傳稿件成功');
      onUpdate();
    } catch (err) {
      Ant.message.error('上傳稿件錯誤');
    }
    actions.setLoading(false);
  };

  const editThumbnail = async (url) => {
    actions.setLoading(true);
    try {
      await actions.editOrderItem({
        id: orderItem.id,
        thumbnail_url: url,
      });
      Ant.message.success('上傳縮圖成功');
      onUpdate();
    } catch (err) {
      Ant.message.error('上傳縮圖錯誤');
    }
    actions.setLoading(false);
  };

  useEffect(() => {
    if (orderItem) {
      setValues({
        supplier: orderItem.supplier,
        status: orderItem.status || 'waiting',
        production_status: orderItem.production_status || 'waiting',
        note: orderItem.note || '',
        reject_reason: orderItem.reject_reason,
      });
    }
  }, [orderItem]);

  return (
    <Wrapper>
      <Block>
        <h2>稿件編號 {orderItem.id}</h2>

        <div className="divider" />
        <Ant.Row gutter={[20, 20]}>
          <Ant.Col lg={6} xxl={4}>
            <div className="row">
              <h4>稿件編號</h4>
              <div>{orderItem.id}</div>
            </div>
            <div className="row">
              <h4>審核狀態</h4>
              <Tag color={REVIEW_STATUS_COLORS[orderItem.status]}>
                {REVIEW_STATUS[orderItem.status]}
              </Tag>
            </div>
            <div className="row">
              <h4>印製狀態</h4>
              <Tag
                color={PRODUCTION_STATUS_COLORS[orderItem.production_status]}>
                {PRODUCTION_STATUS[orderItem.production_status]}
              </Tag>
            </div>
            <div className="row">
              <h4>零售商品</h4>
              <div>{orderItem.file_upload ? 'X 否' : 'V 是'}</div>
            </div>
            {appConfig.paymentProvider === 'payuni' && (
              <div className="row">
                <h4>編輯器</h4>
                <div>{orderItem.is_designed ? 'V 是' : 'X 否'}</div>
              </div>
            )}
            <div className="row">
              <h4>客戶傳檔備註</h4>
              <div>{orderItem.note_2 || attachment?.note || '-'}</div>
            </div>
            {hint && (
              <div className="row">
                <Hint type="warning">{hint}</Hint>
              </div>
            )}
          </Ant.Col>
          <Ant.Col lg={18} xxl={20}>
            <Ant.Table
              pagination={false}
              rowKey="name"
              dataSource={[
                {
                  name: order.custom_note || cartItem.name,
                  quantity: cartItem.quantity,
                  amount: isAmountVisible ? cartItem.price : null,
                },
              ]}
              columns={[
                {title: '商品名稱', dataIndex: 'name', key: 'name'},
                {
                  title: '規格內容',
                  key: 'spec',
                  render: () => (
                    <div>
                      {renderProductConfig(productSpec, order, orderItem)}
                      {order.order_type === 'custom' && (
                        <div>
                          自定規格：
                          {cartItem.custom_note}
                        </div>
                      )}
                    </div>
                  ),
                },
                {
                  title: '商品數量',
                  dataIndex: 'quantity',
                  key: 'quantity',
                  width: 120,
                },
                {
                  title: '金額（含稅）',
                  dataIndex: 'amount',
                  key: 'amount',
                  width: 160,
                },
              ]}
              style={{marginBottom: 10}}
            />

            <Ant.Table
              pagination={false}
              dataSource={[{id: 1}]}
              rowKey="id"
              columns={[
                {
                  title: '審稿',
                  key: 'review',
                  className: 'td-status',
                  render: () =>
                    editReview ? (
                      <div>
                        <div className="field">
                          <h4>審核狀態：</h4>
                          <Ant.Select
                            disabled={
                              disabled ||
                              order.payment_status !== 'success' ||
                              orderItem.status === 'accepted' ||
                              (orderItem.file_upload &&
                                !orderItem.attachment_uploaded)
                            }
                            value={values.status}
                            onChange={(value) => {
                              setValues({
                                status: value,
                                ...(values.status === 'accepted' && {
                                  reject_reason: '',
                                }),
                              });
                            }}
                            style={{width: '100%'}}>
                            {Object.keys(REVIEW_STATUS)
                              .filter((key) => !['all', 'void'].includes(key))
                              .map((x) => (
                                <Ant.Select.Option
                                  value={x}
                                  key={x}
                                  disabled={OrderItemUtil.isReviewDisabled(
                                    x,
                                    order,
                                  )}>
                                  {REVIEW_STATUS[x]}
                                </Ant.Select.Option>
                              ))}
                          </Ant.Select>
                        </div>
                        <div className="field">
                          <h4>拒絕原因：</h4>
                          <Ant.Select
                            disabled={
                              order.payment_status !== 'success' ||
                              values.status !== 'rejected'
                            }
                            value={values.reject_reason}
                            onChange={(value) =>
                              setValues({reject_reason: value})
                            }
                            style={{width: '100%'}}>
                            <Ant.Select.Option value="">-</Ant.Select.Option>
                            <Ant.Select.Option value="完稿尺寸不符合">
                              完稿尺寸不符合
                            </Ant.Select.Option>
                            <Ant.Select.Option value="未做出血">
                              未做出血
                            </Ant.Select.Option>
                            <Ant.Select.Option value="完稿太滿">
                              完稿太滿
                            </Ant.Select.Option>
                            <Ant.Select.Option value="缺圖">
                              缺圖
                            </Ant.Select.Option>
                            <Ant.Select.Option value="文字未轉外框字">
                              文字未轉外框字
                            </Ant.Select.Option>
                            <Ant.Select.Option value="缺（局部光或白墨）黑稿">
                              缺（局部光或白墨）黑稿
                            </Ant.Select.Option>
                            <Ant.Select.Option value="圖片解析度太低">
                              圖片解析度太低
                            </Ant.Select.Option>
                            <Ant.Select.Option value="版本太高">
                              版本太高
                            </Ant.Select.Option>
                            <Ant.Select.Option value="雲端無法下載">
                              雲端無法下載
                            </Ant.Select.Option>
                            <Ant.Select.Option value="其他">
                              其他
                            </Ant.Select.Option>
                          </Ant.Select>
                        </div>
                        <div className="field">
                          <h4>審稿人：</h4>
                          <Ant.Select
                            disabled={disabled || reviewers.length === 0}
                            value={reviewer}
                            onChange={(value) => setReviewer(value)}
                            style={{width: '100%'}}>
                            <Ant.Select.Option key="null" value={null}>
                              未指定
                            </Ant.Select.Option>
                            {reviewers.map((r) => (
                              <Ant.Select.Option key={r.id} value={r.id}>
                                {r.name}
                              </Ant.Select.Option>
                            ))}
                          </Ant.Select>
                        </div>
                        <div className="field">
                          <h4>審稿人員備註：</h4>
                          <Ant.Input.TextArea
                            disabled={disabled}
                            value={values.note}
                            onChange={(e) => setValues({note: e.target.value})}
                            style={{display: 'flex'}}
                          />
                        </div>
                        <Row>
                          <Button
                            size="small"
                            onClick={() => setEditReview(false)}
                            style={{marginRight: 5}}>
                            取消
                          </Button>
                          <Button
                            type="primary"
                            size="small"
                            disabled={
                              disabled || order.payment_status !== 'success'
                            }
                            onClick={() => {
                              if (
                                orderItem.status !== 'accepted' &&
                                values.status === 'accepted'
                              ) {
                                setConfirming(true);
                              } else {
                                submit();
                                assignReviewer();
                              }
                            }}>
                            儲存
                          </Button>
                        </Row>
                      </div>
                    ) : (
                      <div>
                        <div style={{marginBottom: 15}}>
                          <h4>審稿狀態</h4>
                          <div>{REVIEW_STATUS[orderItem.status]}</div>
                        </div>
                        <div style={{marginBottom: 15}}>
                          <h4>拒絕原因</h4>
                          <div>{orderItem.reject_reason || '-'}</div>
                        </div>
                        <div style={{marginBottom: 15}}>
                          <h4>審稿人</h4>
                          <div>
                            {!orderItem.assignee
                              ? '尚未指派'
                              : orderItem.assignee.name == ''
                              ? orderItem.assignee.user.username
                              : orderItem.assignee.name}
                          </div>
                        </div>
                        <div style={{marginBottom: 15}}>
                          <h4>審稿人員備註</h4>
                          <div>{orderItem.note || '-'}</div>
                        </div>
                        <Button
                          size="small"
                          type="primary"
                          color={appConfig.colors.sub}
                          onClick={() => setEditReview(true)}>
                          <Edit
                            size={20}
                            color="#fff"
                            style={{marginRight: 5}}
                          />
                          編輯狀態
                        </Button>
                      </div>
                    ),
                },
                {
                  title: '印製',
                  key: 'production',
                  className: 'td-status',
                  render: () =>
                    editProduction ? (
                      <div>
                        <div className="field">
                          <h4>印製狀態：</h4>
                          <Ant.Select
                            disabled={
                              disabled ||
                              order.payment_status !== 'success' ||
                              orderItem.status !== 'accepted'
                            }
                            value={values.production_status}
                            onChange={(value) =>
                              setValues({production_status: value})
                            }
                            style={{width: '100%'}}>
                            {Object.keys(PRODUCTION_STATUS)
                              .filter(
                                (key) => !['all', 'pending'].includes(key),
                              )
                              .map((x) => (
                                <Ant.Select.Option
                                  value={x}
                                  key={x}
                                  disabled={OrderItemUtil.isProductionDisabled(
                                    x,
                                    order,
                                  )}>
                                  {PRODUCTION_STATUS[x]}
                                </Ant.Select.Option>
                              ))}
                          </Ant.Select>
                        </div>
                        <div className="field">
                          <h4>廠商：</h4>
                          <Ant.Select
                            disabled={disabled || suppliers.length === 0}
                            value={values.supplier || ''}
                            onChange={(value) => {
                              setValues({supplier: value});
                            }}
                            style={{width: '100%'}}>
                            <Ant.Select.Option value={null}>
                              未指定
                            </Ant.Select.Option>
                            {suppliers.map((s) => (
                              <Ant.Select.Option value={s.id} key={s.id}>
                                {s.name}
                              </Ant.Select.Option>
                            ))}
                          </Ant.Select>
                        </div>
                        <Row>
                          <Button
                            size="small"
                            onClick={() => setEditProduction(false)}
                            style={{marginRight: 5}}>
                            取消
                          </Button>
                          <Button
                            type="primary"
                            size="small"
                            disabled={
                              disabled ||
                              order.payment_status !== 'success' ||
                              orderItem.status !== 'accepted'
                            }
                            onClick={() => {
                              submit();
                            }}>
                            儲存
                          </Button>
                        </Row>
                      </div>
                    ) : (
                      <div>
                        <div style={{marginBottom: 15}}>
                          <h4>印製狀態</h4>
                          <div>
                            {PRODUCTION_STATUS[orderItem.production_status]}
                          </div>
                        </div>
                        <div style={{marginBottom: 15}}>
                          <h4>指定廠商</h4>
                          <div>{orderItem.supplier_data?.name || '未指定'}</div>
                        </div>
                        <Button
                          size="small"
                          type="primary"
                          color={appConfig.colors.sub}
                          onClick={() => setEditProduction(true)}>
                          <Edit
                            size={20}
                            color="#fff"
                            style={{marginRight: 5}}
                          />
                          編輯狀態
                        </Button>
                      </div>
                    ),
                },
                {
                  title: '附加檔案',
                  key: 'attachment',
                  className: 'td-status',
                  width: 240,
                  render: () => (
                    <UploadFileButton
                      disabled={disabled}
                      onUpdate={onUpdate}
                      onUpload={({url}) => addAttatchment(url)}
                      file={
                        attachment
                          ? {
                              name: sliceFilename(attachment.file),
                              link: attachment.file,
                            }
                          : null
                      }
                    />
                  ),
                },
                {
                  title: '縮圖',
                  key: 'thumbnail',
                  className: 'td-status',
                  width: 240,
                  render: () => (
                    <div>
                      <UploadFileButton
                        disabled={disabled}
                        onUpdate={onUpdate}
                        onUpload={({url}) => editThumbnail(url)}
                        file={
                          orderItem.thumbnail_url
                            ? {
                                name: sliceFilename(orderItem.thumbnail_url),
                                link: orderItem.thumbnail_url,
                              }
                            : null
                        }
                        limitSize={10 * 1024 * 1024} // 10 MB in bytes
                      />
                      <div style={{height: 5}} />
                      <img
                        style={{
                          maxHeight: 200,
                          maxWidth: '100%',
                          border: 'dashed 1px #aaa',
                        }}
                        src={orderItem.thumbnail_url}
                      />
                    </div>
                  ),
                },
              ]}
            />
          </Ant.Col>
        </Ant.Row>
      </Block>

      <Confirm
        visible={confirming}
        onClose={() => setConfirming(false)}
        onOk={submit}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  word-break: break-word;

  & .ant-table-cell.td-status {
    vertical-align: top;
  }
`;

function Confirm({visible, onClose, onOk}) {
  return (
    <Ant.Modal visible={visible} title={null} onCancel={onClose} footer={null}>
      <Ant.Result
        status="warning"
        title="是否將此稿件切換成合格？"
        subTitle="切換合格後，無法切換審稿狀態，避免訂單狀態錯誤"
        extra={[
          <Button
            key="cancel"
            onClick={onClose}
            style={{display: 'inline-flex'}}>
            取消
          </Button>,
          <Button
            key="ok"
            onClick={() => {
              onClose();
              onOk();
            }}
            style={{display: 'inline-flex'}}>
            確定
          </Button>,
        ]}
      />
    </Ant.Modal>
  );
}
